import { toJS } from "mobx"
import { cast, flow, types } from "mobx-state-tree"
import { api } from "#api"

export const OrganisationModel = types.model("Organisation", {
	id: types.string,
	name: types.string,
})

export const ProfileModel = types.model("Profile", {
	id: types.identifier,
	home_organization: OrganisationModel,
	name: types.string,
	email: types.string,
	provider: types.string,
	external_settings: types.maybeNull(types.frozen()),
	subscriber_id: types.string,
})

export const PipelineModel = types
	.model("Pipeline", {
		id: types.identifier,
		name: types.string,
		space_id: types.string,
		space_name: types.maybe(types.string),
		source_connector: types.maybeNull(
			types.union(
				types.model({
					kind: types.literal("google_pubsub"),
					config: types.model({
						project_id: types.string,
						subscription_id: types.string,
						credentials_json: types.string,
					}),
				}),
				types.model({
					kind: types.literal("postgres"),
					config: types.model({
						db_host: types.string,
						db_port: types.string,
						db_user: types.string,
						db_pass: types.string,
						db_name: types.string,
						replication_slot: types.string,
					}),
				}),
				types.model({
					kind: types.literal("amazon_sqs"),
					config: types.model({
						queue_url: types.string,
						aws_region: types.string,
						aws_access_key: types.string,
						aws_secret_key: types.string,
					}),
				}),
			),
		),
		sink_connector: types.maybeNull(
			types.union(
				types.model({
					kind: types.literal("webhook"),
					config: types.model({
						url: types.string,
						method: types.optional(
							types.union(
								types.literal("GET"),
								types.literal("POST"),
								types.literal("PUT"),
								types.literal("PATCH"),
								types.literal("DELETE"),
							),
							"POST",
						),
						headers: types.frozen(),
					}),
				}),
				types.model({
					kind: types.literal("snowflake_cdc_json"),
					config: types.model({
						account: types.string,
						warehouse: types.string,
						db_user: types.string,
						db_pass: types.string,
						db_name: types.string,
						db_schema: types.string,
					}),
				}),
				types.model({
					kind: types.literal("clickhouse"),
					config: types.model({
						addr: types.string,
						database: types.string,
						username: types.string,
						password: types.string,
						table: types.string,
					}),
				}),
				types.model({
					kind: types.literal("amazon_s3"),
					config: types.model({
						s3_bucket: types.string,
						s3_key: types.string,
						aws_region: types.string,
						aws_access_key: types.string,
						aws_secret_key: types.string,
					}),
				}),
			),
		),
		metadata: types.maybeNull(types.frozen()),
		environments: types.maybeNull(
			types.array(
				types.model({
					name: types.optional(types.string, ""),
					value: types.optional(types.string, ""),
				}),
			),
		),
		created_at: types.string,
		state: types.union(types.literal("running"), types.literal("paused")),
	})
	.actions((self) => {
		return {
			resume: flow(function* () {
				const pipeline: Awaited<ReturnType<typeof api.patchPipeline>> =
					yield api.patchPipeline(
						{ state: "running" },
						{ params: { pipeline_id: self.id } },
					)
				self.state = pipeline.state
			}),

			pause: flow(function* () {
				const pipeline: Awaited<ReturnType<typeof api.patchPipeline>> =
					yield api.patchPipeline(
						{ state: "paused" },
						{ params: { pipeline_id: self.id } },
					)
				self.state = pipeline.state
			}),
		}
	})
	.views((self) => {
		return {
			get isRunning() {
				return self.state === "running"
			},
			get environmentVariables() {
				return toJS(self.environments)
			},
		}
	})

export const SpaceModel = types.model("Space", {
	id: types.identifier,
	name: types.string,
	permission: types.string,
	created_at: types.string,
})

export const PipelinesModel = types.model("Pipelines", {
	pipelines: types.array(
		types.model("Pipeline", {
			id: types.identifier,
			name: types.string,
			space_id: types.string,
			space_name: types.maybe(types.string),
			metadata: types.maybeNull(types.frozen()),
			created_at: types.string,
		}),
	),
	count: types.optional(types.number, 0),
})

export const SpacesModel = types.model("Spaces", {
	spaces: types.array(SpaceModel),
})

export const AccessTokenModel = types.model("AccessToken", {
	id: types.identifier,
	name: types.string,
	token: types.string,
	created_at: types.string,
})

export const LogModel = types.model("Log", {
	level: types.string,
	severity_code: types.number,
	timestamp: types.string,
	payload: types.frozen<{ message: string }>(),
})
